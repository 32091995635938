.listings {
    width: 100%;
    min-height: 60vh;
    /* margin: 20px 0px; */
    background-color: rgb(16, 20, 39, 0.8);
    border-radius: 10px;
    padding: 10px;
    /* color: rgba(255, 255, 255, 0.63); */
}
.listings .element{
    padding: 0 15px;
    width: 100%;
    background-color: rgb(130, 130, 130, 0.1);
    border-radius: 10px;
}
.listings .element .item{
    width: 100%;
    display: flex;
    align-items: center;
    height: fit-content;
    padding: 10px 0px;
}
.listings .element .item .icon{
    padding: 5px;
    margin-right: 10px;
    flex-basis: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: bold;
    display: grid;
    place-items: center;
    height: 40px;
    font-size: 20px;
    border-radius: 5px;
    background-color: rgba(8, 108, 190, 0.5);
}
.listings .element .item .title{
    width: 100%;
    /* white-space: nowrap; */
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-weight: bold;
}
.listings .element .item .title .sub{
    font-size: small;
    font-weight: normal;
    color: rgb(190, 188, 188);
}
.listings .element .item .title:hover{
    overflow: visible; 
    white-space: normal;
}
.listings .element .item .value{
    white-space: nowrap;
    float: right;
    text-align: right;
}
.listings .element .item .value i{
    margin: 0 5px;
}
.listings .element .item .dropdown-btn{
    font-size: 20px;
    margin-left: 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.63);
}
.listings .dropdown-btn:hover{
    color: #fff !important;
}
.listings .expanded{
    width: 100%;
    padding-bottom:10px;
}
.listings .expanded .key-value-pairs{
    width: 100%;
    padding-bottom: 20px;
}
.listings .expanded .key-value-pairs .key{
    width: 40%;
    float: left;
    font-weight: 500;
}
.listings .expanded .key-value-pairs .value{
    width: 60%;
    float: right;
    text-align: right;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
}
.listings .expanded button{
    width: 100%;
    margin-top: 10px;
    padding: 5px 0;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: small;
    background-color: rgb(249, 202, 38);
    border-radius: 10px;
    cursor: pointer;
}
.listings .expanded button:hover{
    background-color: rgb(194, 159, 34);
}

@media screen and (max-width: 600px) {
    .listings {
        padding: 10px;
        font-size: smaller;
    }
.listings .element .item .dropdown-btn{
    margin-left: 10px;

}
}

.view-more {
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: center;
}

.empty-msg {
    width: 100%;
    padding: 100px 0;
    text-align: center;
}
